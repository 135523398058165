import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Slider, MultiColorSlider, StyledSlider, NotifiedSlider } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Slider" title="Slider" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-slider"
    }}>{`Basic slider`}</h2>
    <Playground __position={1} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    valuePosition=\"right\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} valuePosition="right" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "small-slider"
    }}>{`Small slider`}</h2>
    <Playground __position={2} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    scale=\"small\"\n    valuePosition=\"right\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} scale="small" valuePosition="right" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "negative-slider"
    }}>{`Negative slider`}</h2>
    <Playground __position={3} __code={'<FormComponentDemo initialValue={-10} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    max={0}\n    min={-40}\n    onChange={(event, value) => console.log(value)}\n    valuePosition=\"right\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={-10} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" max={0} min={-40} onChange={(event, value) => console.log(value)} valuePosition="right" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "ticked-slider"
    }}>{`Ticked slider`}</h2>
    <Playground __position={4} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    max={100}\n    min={0}\n    onChange={(event, value) => console.log(value)}\n    step={1}\n    tick={10}\n    valuePosition=\"right\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" max={100} min={0} onChange={(event, value) => console.log(value)} step={1} tick={10} valuePosition="right" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "ticked-snap-slider"
    }}>{`Ticked snap slider`}</h2>
    <Playground __position={5} __code={'<FormComponentDemo initialValue={1.5} property=\"value\" type=\"slider\">\n  <Slider\n    snap\n    aria-label=\"Super fantastic label\"\n    max={2}\n    min={0}\n    onChange={(event, value) => console.log(value)}\n    step={0.5}\n    tick={0.5}\n    valuePosition=\"right\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={1.5} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider snap aria-label="Super fantastic label" max={2} min={0} onChange={(event, value) => console.log(value)} step={0.5} tick={0.5} valuePosition="right" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "labeled-slider"
    }}>{`Labeled slider`}</h2>
    <Playground __position={6} __code={'<FormComponentDemo initialValue={9.5} property=\"value\" type=\"slider\">\n  <Slider\n    max={12}\n    min={2}\n    onChange={(event, value) => console.log(value)}\n    step={0.1}\n    valuePosition=\"top\"\n    width=\"100%\"\n  >\n    Super fantastic label\n  </Slider>\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={9.5} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider max={12} min={2} onChange={(event, value) => console.log(value)} step={0.1} valuePosition="top" width="100%" mdxType="Slider">
      Super fantastic label
    </Slider>
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "disabled-slider"
    }}>{`Disabled slider`}</h2>
    <Playground __position={7} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    disabled\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider disabled aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "success-slider"
    }}>{`Success slider`}</h2>
    <Playground __position={8} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    validity=\"success\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="success" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "warning-slider"
    }}>{`Warning slider`}</h2>
    <Playground __position={9} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    validity=\"warning\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="warning" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "danger-slider"
    }}>{`Danger slider`}</h2>
    <Playground __position={10} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    validity=\"danger\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="danger" width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "contrast-slider"
    }}>{`Contrast slider`}</h2>
    <Playground __position={11} __code={'<FormComponentDemo contrast initialValue={75} property=\"value\" type=\"slider\">\n  <Slider\n    contrast\n    aria-label=\"Super fantastic label\"\n    onChange={(event, value) => console.log(value)}\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo contrast initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <Slider contrast aria-label="Super fantastic label" onChange={(event, value) => console.log(value)} width="100%" mdxType="Slider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "styled-slider"
    }}>{`Styled slider`}</h2>
    <Playground __position={12} __code={'<FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n  <StyledSlider\n    aria-label=\"Super fantastic label\"\n    handleBackgroundColor=\"#283992\"\n    onChange={(event, value) => console.log(value)}\n    tickBackgroundColor=\"#e4e4e4\"\n    trackBackgroundColor=\"#e4e4e4\"\n    trackFillBackgroundColor=\"#642da0\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
    <StyledSlider aria-label="Super fantastic label" handleBackgroundColor="#283992" onChange={(event, value) => console.log(value)} tickBackgroundColor="#e4e4e4" trackBackgroundColor="#e4e4e4" trackFillBackgroundColor="#642da0" width="100%" mdxType="StyledSlider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "multi-color-slider"
    }}>{`Multi-color slider`}</h2>
    <Playground __position={13} __code={'<FormComponentDemo initialValue={2} property=\"value\" type=\"slider\">\n  <MultiColorSlider\n    aria-label=\"Super fantastic label\"\n    colors={[\'danger\', \'warning\', \'success\', \'accent\']}\n    formatValue={value =>\n      [\'danger\', \'warning\', \'success\', \'accent\'][value - 1] || \'-\'\n    }\n    onChange={(event, value) => console.log(value)}\n    valuePosition=\"right\"\n    variant=\"divider\"\n    width=\"100%\"\n  />\n</FormComponentDemo>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FormComponentDemo initialValue={2} property="value" type="slider" mdxType="FormComponentDemo">
    <MultiColorSlider aria-label="Super fantastic label" colors={['danger', 'warning', 'success', 'accent']} formatValue={value => ['danger', 'warning', 'success', 'accent'][value - 1] || '-'} onChange={(event, value) => console.log(value)} valuePosition="right" variant="divider" width="100%" mdxType="MultiColorSlider" />
  </FormComponentDemo>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={14} __code={'<Looper\n  list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n  render={level => (\n    <FormComponentDemo initialValue={75} property=\"value\" type=\"slider\">\n      <NotifiedSlider\n        aria-label=\"Super fantastic label\"\n        level={level}\n        notification={`This is an example ${level} notification.`}\n        onChange={(event, value) => console.log(value)}\n        valuePosition=\"right\"\n        width=\"100%\"\n      />\n    </FormComponentDemo>\n  )}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      PageTitle,
      FormComponentDemo,
      Slider,
      MultiColorSlider,
      StyledSlider,
      NotifiedSlider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <FormComponentDemo initialValue={75} property="value" type="slider" mdxType="FormComponentDemo">
        <NotifiedSlider aria-label="Super fantastic label" level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} valuePosition="right" width="100%" mdxType="NotifiedSlider" />
      </FormComponentDemo>} mdxType="Looper" />
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Slider} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      